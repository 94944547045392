<template>
  <span v-if="!wagen || !(wagen.nummerplaat || wagen.naam)">
    <b v-show="label">{{ label }}: </b>{{ geenDataText }}
  </span>
  <span v-else>
    <b v-show="label">{{ label }}: </b>{{ wagen.nummerplaat }} {{ wagen.naam }}
  </span>
</template>

<script>
export default {
  props: {
    wagen: Object,
    geenDataText: {
      type: String,
      default: '?',
    },
    label: {
      type: String,
      default: 'Wagen',
    },
  },
}
</script>

<style></style>
